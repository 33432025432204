import Raven from 'raven-js';
import { RECURRING_BILLING_FREQUENCIES } from '../constants/RecurringBillingFrequencies';
import { isOptional } from '../types/utils/typeguards';
export const getIsAnyRecurring = (lineItems, selectedOptionalLineItems) => {
  return lineItems.some((lineItem, index) => {
    if (isOptional(lineItem) && selectedOptionalLineItems && !selectedOptionalLineItems[index]) {
      return false;
    }
    return lineItem.recurringBillingTerms;
  });
};
export function getLineItemRecurringFrequency(lineItem) {
  if (!lineItem || !lineItem.recurringBillingTerms) {
    return null;
  }
  const {
    recurringBillingTerms: {
      intervalLength,
      intervalUnit
    }
  } = lineItem;
  if (intervalLength === 1 && intervalUnit === 'DAYS') {
    return RECURRING_BILLING_FREQUENCIES.DAYS;
  } else if (intervalLength === 1 && intervalUnit === 'MONTHS') {
    return RECURRING_BILLING_FREQUENCIES.MONTHS;
  } else if (intervalLength === 3 && intervalUnit === 'MONTHS') {
    return RECURRING_BILLING_FREQUENCIES.QUARTERS;
  } else if (intervalLength === 6 && intervalUnit === 'MONTHS') {
    return RECURRING_BILLING_FREQUENCIES.SIX_MONTHS;
  } else if (intervalLength === 1 && intervalUnit === 'YEARS') {
    return RECURRING_BILLING_FREQUENCIES.YEARS;
  } else {
    const error = new Error('Unsupported recurring billing configuration');
    Raven.captureException(error);
    throw error;
  }
}
export function getRecurringBillingFrequency(intervalUnit, intervalLength) {
  if (intervalLength === 1 && intervalUnit === 'DAYS') {
    return RECURRING_BILLING_FREQUENCIES.DAYS;
  } else if (intervalLength === 1 && intervalUnit === 'MONTHS') {
    return RECURRING_BILLING_FREQUENCIES.MONTHS;
  } else if (intervalLength === 3 && intervalUnit === 'MONTHS') {
    return RECURRING_BILLING_FREQUENCIES.QUARTERS;
  } else if (intervalLength === 6 && intervalUnit === 'MONTHS') {
    return RECURRING_BILLING_FREQUENCIES.SIX_MONTHS;
  } else if (intervalLength === 1 && intervalUnit === 'YEARS') {
    return RECURRING_BILLING_FREQUENCIES.YEARS;
  } else {
    const error = new Error('Unsupported recurring billing configuration');
    Raven.captureException(error);
    throw error;
  }
}
export function getLineItemRecurringFrequencyBySubtotal(intervalUnit, intervalLength) {
  if (intervalLength === 1 && intervalUnit === 'DAYS') {
    return RECURRING_BILLING_FREQUENCIES.DAYS;
  }
  if (intervalLength === 1 && intervalUnit === 'MONTHS') {
    return RECURRING_BILLING_FREQUENCIES.MONTHS;
  }
  if (intervalLength === 3 && intervalUnit === 'MONTHS') {
    return RECURRING_BILLING_FREQUENCIES.QUARTERS;
  }
  if (intervalLength === 6 && intervalUnit === 'MONTHS') {
    return RECURRING_BILLING_FREQUENCIES.SIX_MONTHS;
  }
  if (intervalLength === 1 && intervalUnit === 'YEARS') {
    return RECURRING_BILLING_FREQUENCIES.YEARS;
  }
  if (intervalLength === 2 && intervalUnit === 'YEARS') {
    return RECURRING_BILLING_FREQUENCIES.TWO_YEARS;
  }
  if (intervalLength === 3 && intervalUnit === 'YEARS') {
    return RECURRING_BILLING_FREQUENCIES.THREE_YEARS;
  }
  const error = new Error('Unsupported recurring billing configuration');
  Raven.captureException(error);
  throw error;
}
export function getRecurringSubtotal(lineItems) {
  return lineItems.reduce((subtotal, lineItem) => {
    if (!lineItem.get('recurringBillingTerms')) {
      return subtotal;
    }
    return subtotal + lineItem.get('amount');
  }, 0);
}

/**
 * diffMilliseconds returns the difference between two timestamps in milliseconds
 */
export const diffMilliseconds = (stampA, stampB) => stampB - stampA;

/**
 * diffDays returns the difference between two timestamps in days
 */
export const diffDays = (stampA, stampB) => diffMilliseconds(stampA, stampB) / (1000 * 60 * 60 * 24);