import { useTheme } from 'styled-components';
import { styleCategoryMap } from '../utils/styleCategoryMap';
/**
 * This hook maps style properties provided to components (e.g. Text, View,
 * Flex) to their corresponding CSS values defined within a theme.
 * For properties that do not correspond to a theme category, it uses the
 * value directly without theme validation.
 *
 * @param styleProps - The style properties provided to the component.
 * @param opts - An options object for customizing styles after initial computation.
 *
 * @returns A new object containing valid CSS properties and their values. E.g:
 *
 * `<View padding="1000">...</View>`
 *
 * Here, the `padding` prop is looked up in the `styleCategoryMap` object
 * to find the corresponding theme category, which is `space` in this case.
 * The padding value `1000` is then looked up in the `space`
 * category of the theme object, and the corresponding value is returned.
 * The object returned from the function would then be: `{ padding: '40px' }`.
 */
export const useMapStylePropsToCSS = (styleProps, opts = {}) => {
  const theme = useTheme();

  // Temp type to satisfy TypeScript. Will be `T` at the end.
  let cssStyles = {};
  Object.keys(styleProps).forEach(propKey => {
    const key = propKey;
    const value = styleProps[key];

    // Necessary type cast because the `styleCategoryMap` object contains
    // only all possible style props for which token values have been defined
    // in the design system. In cases where `key` is a style property without
    // tokens defined, like `width`, TypeScript would throw an error.
    const themeCategory = styleCategoryMap[key];
    if (value) {
      // When a style property doesn't have a corresponding theme category,
      // like `width`, we use the value directly without theme validation.
      if (!themeCategory) {
        cssStyles[key] = value;
      } else {
        const themeCategoryStyles = theme[themeCategory];

        // Otherwise, we know that the style property has a corresponding
        // theme category, so we look up the token value in the theme object.
        if (themeCategoryStyles[value]) {
          cssStyles[key] = themeCategoryStyles[value];
        }
      }
    }
  });
  if (opts.customizeStyles) {
    cssStyles = opts.customizeStyles({
      theme,
      styleProps,
      cssStyles: Object.assign({}, cssStyles)
    });
  }
  return cssStyles;
};