'use es6';

import Raven from 'raven-js';
import { getExtraErrorData } from './getExtraErrorData';
import enviro from 'enviro';
import getIn from 'transmute/getIn';
import pipe from 'transmute/pipe';
const bCommerce = 'bCommerce';

// Copied from https://git.hubteam.com/HubSpot/growth-onboarding-reliability/blob/85385d9c1f7a8c3c36009135d8dd4099c36b0e3b/growth-onboarding-reliability/static/js/utils/raven.js

// Network errors to isolate into groups regardless of URL
const ISOLATED_NETWORK_CODES = ['ABORT', 'NETWORKERROR', 'TIMEOUT', '0', '401', '502'];

// taken from https://stackoverflow.com/a/3809435
const uriRegex =
// eslint-disable-next-line no-useless-escape
/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

// removes portalId or publicUrlKey from URL
export function stripPortalIdOrKey(str = '') {
  const portalIdOrKeyRegex = new RegExp(/\/(\w+\d+\w+)($|\/)/, 'g');
  return str.replace(portalIdOrKeyRegex, '/<portal_id_or_key>/');
}
function stripQueryParams(url = '') {
  return url.replace(/\?.*$/, '');
}
export function cleanseUrl(url = '') {
  return pipe(stripQueryParams, stripPortalIdOrKey)(url);
}
function splitIntoLines(str = '') {
  return str.split(/\r?\n/g);
}
function containsUri(str = '') {
  return uriRegex.test(str);
}
function parseUri(str = '') {
  const matches = uriRegex.exec(str);
  return matches && matches[0];
}

// taken from https://stackoverflow.com/a/1981366
function stripRepeatedWhitespace(str = '') {
  return str.replace(/\s\s+/g, ' ');
}
export function isNetworkError(error) {
  return !!((error.errorCode || error.status) && error.options && error.options.url);
}

/**
 * We want to avoid having multiple sentries for the same error but we still want to track it.
 * The problem is that every request has the portalId in the query string, which results in a
 * different error message, thus a different error.
 * What we are doing here is manually capturing an error with Sentry and adding a fingerprint.
 * Sentry will group all the errors with the same fingerprint regardless of the actual error.
 * The fingerprint for each error network is:
 * url (without query string) + request verb + response status code
 */
export function catchAndRethrowNetworkError(error, bCommerceTeam = bCommerce) {
  if (isNetworkError(error)) {
    const code = error.errorCode || error.status;
    const cleansedUrl = cleanseUrl(error.options.url);
    const method = error.options.method;
    const envPrefix = enviro.isQa() ? 'QA: ' : '';
    const message = `${envPrefix}${method} ${code}: ${cleansedUrl}`;
    Raven.captureException(message, {
      fingerprint: ISOLATED_NETWORK_CODES.includes(code.toString()) ? [code] : [code, method, cleansedUrl],
      extra: getExtraErrorData(error),
      tags: {
        [bCommerceTeam]: true
      }
    });
    if (window.newrelic && window.newrelic.noticeError) {
      const builtError = Object.assign(new Error(), error, {
        message
      });
      window.newrelic.noticeError(builtError, {
        [bCommerceTeam]: true,
        source: 'noticeError'
      });
    }
  }
  throw error;
}

/** Duplicate of catchAndRethrowNetworkError but meant to be used by any endpoints that contain
 * PII so as to avoid sending sensitive data to Sentry
 */
export function catchAndRethrowSensitiveNetworkError(error, bCommerceTeam = bCommerce) {
  if (isNetworkError(error)) {
    const code = error.errorCode || error.status;
    const cleansedUrl = cleanseUrl(error.options.url);
    const method = error.options.method;
    const envPrefix = enviro.isQa() ? 'QA: ' : '';
    const message = `${envPrefix}${method} ${code}: ${cleansedUrl}`;
    Raven.captureMessage(message, {
      fingerprint: ISOLATED_NETWORK_CODES.includes(code.toString()) ? [code] : [code, method, cleansedUrl],
      extra: getExtraErrorData(error, {
        sensitiveData: true
      }),
      tags: {
        [bCommerceTeam]: true
      }
    });
    if (window.newrelic && window.newrelic.noticeError) {
      const builtError = Object.assign(new Error(), error, {
        message
      });
      window.newrelic.noticeError(builtError, {
        [bCommerceTeam]: true,
        source: 'noticeError'
      });
    }
  }
  throw error;
}
function parseStacktracedError(message, type = 'Unspecified Error') {
  const [firstLine] = splitIntoLines(message);
  if (containsUri(firstLine)) {
    return `Stacktraced Network Error: ${cleanseUrl(parseUri(firstLine))}`;
  } else {
    // Strip any meaningless characters or strings from JS exceptions.
    return `${type}: ${stripRepeatedWhitespace(message)}`;
  }
}
export function getFingerprint(data) {
  // The gist of the logic here is that we continuously try and find network errors through:
  // - `data` having an `exception` where a URL is mentioned in the first line
  // - `data` having a `request` property
  // - `data` having a `message` where a URL is mentioned in the first line
  // Otherwise we strip for excess whitespace and treat as a JS error.
  let fingerprint;
  if (getIn(['exception', 'values', 'length'])(data) > 0) {
    const [exception] = data.exception.values;
    fingerprint = [parseStacktracedError(exception.value, exception.type)];
  } else if (data.request && data.request.url) {
    // Strip portal ids and query params from requests...
    fingerprint = [cleanseUrl(data.request.url)];
  } else {
    fingerprint = [parseStacktracedError(data.message)];
  }
  // This will cause raven to ignore its default grouping behaviour and go solely
  // off our scrubbed error message
  return fingerprint;
}
export function ravenDataCallback(data, original) {
  // If the raven request already has a fingerprint, it has already been handled by
  // upstream from us by either our own network failure catches or by other FE tools
  // (e.g. usage tracking), and doesn't need to be assigned a fingerprint
  if (!data.fingerprint) {
    data.fingerprint = getFingerprint(data);
  }
  return original ? original(data) : data;
}