import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["onBlur", "onChange", "onFocus"],
  _excluded2 = ["error", "aria-invalid"];
import { useState } from 'react';
import { mergeProps, useId as reactAriaUseId } from 'react-aria';
import { useTrellisFocusRing } from '../../hooks';
import { useTrellisFieldControl } from '../Field';
import { useMaybeStopPropagation } from '../AppProvider';
export const useTrellisTextField = props => {
  const [isComposing, setIsComposing] = useState(false);
  const {
      onBlur,
      onChange,
      onFocus
    } = props,
    inputProps = _objectWithoutPropertiesLoose(props, _excluded);
  const {
    focusProps,
    focusRingStyles
  } = useTrellisFocusRing({
    isTextInput: true
  });
  const {
    maybeStopPropagation
  } = useMaybeStopPropagation();
  const fieldProps = useTrellisFieldControl();
  const handleOnBlur = event => {
    maybeStopPropagation(event);
    if (onBlur) {
      onBlur(event);
    }
  };
  const handleOnChange = event => {
    maybeStopPropagation(event);
    if (onChange) {
      onChange(event);
    }
  };
  const handleOnFocus = event => {
    maybeStopPropagation(event);
    if (onFocus) {
      onFocus(event);
    }
  };
  const handleOnCompositionStart = () => setIsComposing(true);
  const handleOnCompositionEnd = () => setIsComposing(false);
  const handleOnKeyDown = event => {
    if (isComposing) event.stopPropagation();
  };
  const handleOnKeyUp = event => {
    if (isComposing) event.stopPropagation();
  };
  const _mergeProps = mergeProps(focusProps, fieldProps, Object.assign({}, inputProps, {
      onBlur: handleOnBlur,
      onChange: handleOnChange,
      onFocus: handleOnFocus,
      onCompositionStart: handleOnCompositionStart,
      onCompositionEnd: handleOnCompositionEnd,
      onKeyDown: handleOnKeyDown,
      onKeyUp: handleOnKeyUp
    })),
    {
      error,
      'aria-invalid': ariaInvalid
    } = _mergeProps,
    controlProps = _objectWithoutPropertiesLoose(_mergeProps, _excluded2);
  const state = error || ariaInvalid === true ? 'error' : controlProps.disabled ? 'disabled' : controlProps.readOnly ? 'readOnly' : 'idle';
  return {
    controlProps: Object.assign({}, controlProps, getAriaInvalid(inputProps, ariaInvalid)),
    state,
    focusRingStyles
  };
};
export const useAdditionalDescriptors = ({
  prefix,
  suffix,
  trailingSlug,
  leadingSlug,
  controlAriaDescribedBy
}) => {
  const descriptorIds = {
    prefix: reactAriaUseId(),
    suffix: reactAriaUseId(),
    leadingSlug: reactAriaUseId(),
    trailingSlug: reactAriaUseId()
  };
  const descriptors = [];
  if (controlAriaDescribedBy) descriptors.push(controlAriaDescribedBy);
  if (prefix) descriptors.push(descriptorIds.prefix);
  if (suffix) descriptors.push(descriptorIds.suffix);
  if (leadingSlug) descriptors.push(descriptorIds.leadingSlug);
  if (trailingSlug) descriptors.push(descriptorIds.trailingSlug);
  const mergedDescriptors = descriptors.join(' ').trim();
  let mergedAriaDescribedBy = {};
  if (mergedDescriptors.length > 0) {
    mergedAriaDescribedBy = {
      'aria-describedby': mergedDescriptors
    };
  }
  return {
    descriptorIds,
    mergedAriaDescribedBy
  };
};
function getAriaInvalid(props, fieldAriaInvalid) {
  if (props.error === false || props['aria-invalid'] === false) {
    return {
      'aria-invalid': undefined
    };
  }
  if (props['aria-invalid'] === true || props.error === true) {
    return {
      'aria-invalid': true
    };
  }
  return {
    'aria-invalid': fieldAriaInvalid ? true : undefined
  };
}