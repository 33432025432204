import { createGlobalStyle } from 'styled-components';
export const GlobalStyles = createGlobalStyle(({
  theme: _theme
}) => {
  const theme = _theme;
  return {
    '*': {
      boxSizing: 'border-box'
    },
    '*::before': {
      boxSizing: 'border-box'
    },
    '*::after': {
      boxSizing: 'border-box'
    },
    body: Object.assign({}, theme.typography['body-100'], {
      color: theme.color['text-primary-default']
    })
  };
});