export const getSizeStyles = size => {
  switch (size) {
    case 'medium':
      {
        return {
          textMarginShort: '400',
          textMarginLong: '1200',
          iconSize: 'icon-300'
        };
      }
    case 'small':
      {
        return {
          textMarginShort: '50',
          textMarginLong: '1300',
          iconSize: 'icon-100'
        };
      }
    case 'extra-small':
      {
        return {
          textMarginShort: '1100',
          textMarginLong: '50',
          iconSize: 'icon-100'
        };
      }
    default:
      {
        const __exhaustiveCheck = size;
        throw new Error(`Unhandled \`size\` argument:\n ${size}`);
      }
  }
};
export const getToggleHandleCssObject = (size, checked, disabled, theme) => {
  const sharedStyles = {
    borderRadius: theme.borderRadius[100],
    borderColor: checked && !disabled ? theme.color['fill-secondary-default'] : theme.color['fill-tertiary-pressed'],
    borderWidth: theme.borderWidth[100],
    borderStyle: 'solid',
    backgroundColor: theme.color[disabled ? 'fill-surface-raised' : 'fill-surface-default'],
    boxShadow: theme.boxShadow[100],
    position: 'absolute',
    top: '-1px'
  };
  const handleSizeMap = {
    medium: theme.space[1000],
    small: theme.space[800],
    'extra-small': `(${theme.space['600']} + ${theme.space['50']})`
  };
  return Object.assign({
    height: `calc(${handleSizeMap[size]})`,
    width: `calc(${handleSizeMap[size]})`,
    left: checked ? `calc(100%  + 1px - ${handleSizeMap[size]})` : '-1px'
  }, sharedStyles);
};
export const getContainerCssObject = (size, checked, variant, disabled, theme) => {
  const handleCssObject = getToggleHandleCssObject(size, checked, disabled, theme);
  let backgroundColorKey;
  if (disabled) {
    backgroundColorKey = 'fill-surface-raised';
  } else {
    if (checked) {
      backgroundColorKey = 'fill-secondary-default';
    } else if (variant === 'on-dark') {
      backgroundColorKey = 'fill-accent-neutral-hover';
    } else {
      backgroundColorKey = 'fill-tertiary-default';
    }
  }
  let borderColorKey;
  if (checked && !disabled) {
    borderColorKey = 'fill-secondary-default';
  } else if (variant === 'on-dark' && !disabled) {
    borderColorKey = 'fill-accent-neutral-hover';
  } else {
    borderColorKey = 'border-primary-default';
  }
  return {
    height: handleCssObject.height,
    borderWidth: theme.borderWidth[100],
    borderStyle: 'solid',
    borderRadius: theme.borderRadius[100],
    borderColor: theme.color[borderColorKey],
    backgroundColor: theme.color[backgroundColorKey],
    position: 'relative',
    cursor: !disabled ? 'pointer' : 'not-allowed',
    transition: 'background-color 0.3s ease-in-out, border-color 0.3s ease-in-out'
  };
};
export const getTextCssObject = (size, checked, theme) => {
  const sizeStyles = getSizeStyles(size);
  return {
    position: 'relative',
    height: '100%',
    display: 'flex',
    minWidth: size === 'medium' ? '30px' : 0,
    justifyContent: 'center',
    marginLeft: checked ? theme.space[sizeStyles.textMarginShort] : theme.space[sizeStyles.textMarginLong],
    marginRight: checked ? theme.space[sizeStyles.textMarginLong] : theme.space[sizeStyles.textMarginShort],
    alignItems: 'center',
    transition: 'color 0.3s ease-in-out'
  };
};