/**
 * Mapping object between CSS style properties (boxShadow, padding, etc)
 * accepted by components (as defined in the `types/[propcategory]-style.ts`
 * files) and their corresponding token categories (fontSize, space, etc).
 * These properties are the only ones that have semantic or component
 * token values defined in the design system. E.g:
 *
 * `<View padding="1000">...</View>`
 *
 * Here, the `padding` prop is mapped to the `space` token category
 * of the theme object.
 */
export const styleCategoryMap = {
  // Background
  backgroundColor: 'color',
  // Box shadow
  boxShadow: 'boxShadow',
  // Border
  borderBlockWidth: 'borderWidth',
  borderBlockStartWidth: 'borderWidth',
  borderBlockEndWidth: 'borderWidth',
  borderInlineWidth: 'borderWidth',
  borderInlineStartWidth: 'borderWidth',
  borderInlineEndWidth: 'borderWidth',
  borderStartStartRadius: 'borderRadius',
  borderStartEndRadius: 'borderRadius',
  borderEndStartRadius: 'borderRadius',
  borderEndEndRadius: 'borderRadius',
  borderBlockColor: 'color',
  borderBlockStartColor: 'color',
  borderBlockEndColor: 'color',
  borderInlineColor: 'color',
  borderInlineStartColor: 'color',
  borderInlineEndColor: 'color',
  // Layout
  gap: 'space',
  rowGap: 'space',
  columnGap: 'space',
  // Position
  insetBlockStart: 'space',
  insetBlockEnd: 'space',
  insetInlineStart: 'space',
  insetInlineEnd: 'space',
  // Space
  paddingInline: 'space',
  paddingInlineStart: 'space',
  paddingInlineEnd: 'space',
  paddingBlock: 'space',
  paddingBlockStart: 'space',
  paddingBlockEnd: 'space',
  marginInline: 'space',
  marginInlineStart: 'space',
  marginInlineEnd: 'space',
  marginBlock: 'space',
  marginBlockStart: 'space',
  marginBlockEnd: 'space',
  // Text
  color: 'color',
  typography: 'typography'
};