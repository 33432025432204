/**
 * This helper retrieves variant tokens for a particular variant.
 *
 * @param variant - The current variant of the Input.
 *
 * @returns A new object containing tokens for the variant's idle, error, readOnly, and disabled states.
 *
 */
export const getVariantTokens = variant => {
  const tokens = {
    default: {
      idle: {
        backgroundColor: 'fill-surface-raised',
        borderColor: 'border-primary-default',
        placeholder: {
          color: 'text-primary-disabled'
        },
        value: {
          color: 'text-primary-default'
        }
      },
      error: {
        backgroundColor: 'fill-surface-raised',
        borderColor: 'border-alert-default',
        placeholder: {
          color: 'text-primary-disabled'
        },
        value: {
          color: 'text-primary-default'
        }
      },
      readOnly: {
        backgroundColor: 'fill-tertiary-default',
        borderColor: 'border-primary-disabled',
        placeholder: {
          color: 'text-primary-disabled'
        },
        value: {
          color: 'text-primary-default'
        }
      },
      disabled: {
        backgroundColor: 'fill-tertiary-default',
        borderColor: 'border-primary-disabled',
        placeholder: {
          color: 'text-primary-disabled'
        },
        value: {
          color: 'text-primary-disabled'
        }
      }
    },
    'on-surface': {
      idle: {
        backgroundColor: 'fill-surface-default',
        borderColor: 'border-primary-default',
        placeholder: {
          color: 'text-primary-disabled'
        },
        value: {
          color: 'text-primary-default'
        }
      },
      error: {
        backgroundColor: 'fill-surface-default',
        borderColor: 'border-alert-default',
        placeholder: {
          color: 'text-primary-disabled'
        },
        value: {
          color: 'text-primary-default'
        }
      },
      readOnly: {
        backgroundColor: 'fill-tertiary-default',
        borderColor: 'border-primary-disabled',
        placeholder: {
          color: 'text-primary-disabled'
        },
        value: {
          color: 'text-primary-default'
        }
      },
      disabled: {
        backgroundColor: 'fill-tertiary-default',
        borderColor: 'border-primary-disabled',
        placeholder: {
          color: 'text-primary-disabled'
        },
        value: {
          color: 'text-primary-disabled'
        }
      }
    }
  };
  return tokens[variant];
};