/**
  @file statuses correspond to the "status" field on our session endpoints, and result in different top-level screens rendering.

  Checkout path statuses documented in payments-ui-app/static/js/types/CheckoutSessionGetResponse.ts
  SOURCE_ID_PAID: set in FE as a response to BE checkout submission error. Indicates that a payment is already being processed for a session.
 */

export const OPEN = 'OPEN';
export const CANCELLED = 'CANCELLED';
export const EXPIRED = 'EXPIRED';
export const PROCESSING = 'PROCESSING';
export const SUCCEEDED = 'SUCCEEDED';
export const FAILED = 'FAILED';
export const SOURCE_ID_PAID = 'SOURCE_ID_PAID';
export const PENDING = 'PENDING';
export const COMPLETED = 'COMPLETED';