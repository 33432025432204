import { useEffect } from 'react';
import { applyDiscount } from '../../components/discount/ApplyDiscountHelpers';
import { setDiscountCodeErrorMessage } from '../../state/DiscountCode/discountCodeSlice';
import { useDispatch, useSelector } from 'react-redux';
import { usePaymentFormContext } from '../../context/PaymentFormContext';
import { useDataFetchingClient } from 'data-fetching-client';
import { getBuyerOverridesLineItems } from '../../state/BuyerOverrides/selectors';

/**
 * @note Handles BSQ and open amount use cases where we have to reapply the discount code
 */
export default function useReapplyDiscountCode({
  isDiscountCodeApplied,
  checkoutSession,
  isIframePreviewUrl,
  minimumAmount
}) {
  const reduxDispatch = useDispatch();
  const {
    formState,
    dispatch
  } = usePaymentFormContext();
  const discountCode = formState.get('discountCode');
  const client = useDataFetchingClient();
  const {
    adjustments
  } = checkoutSession;
  const buyerOverridesLineItems = useSelector(getBuyerOverridesLineItems);
  useEffect(() => {
    reduxDispatch(setDiscountCodeErrorMessage(''));
    if (isDiscountCodeApplied) {
      applyDiscount({
        buyerOverrides: {
          lineItems: buyerOverridesLineItems,
          discountCode
        },
        checkoutSession,
        isIframePreviewUrl,
        minimumAmount,
        dispatch,
        reduxDispatch,
        client
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adjustments, buyerOverridesLineItems]);
}