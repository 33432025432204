export const borderStyleProps = {
  borderBlockWidth: true,
  borderBlockStartWidth: true,
  borderBlockEndWidth: true,
  borderInlineWidth: true,
  borderInlineStartWidth: true,
  borderInlineEndWidth: true,
  borderBlockColor: true,
  borderBlockStartColor: true,
  borderBlockEndColor: true,
  borderInlineColor: true,
  borderInlineStartColor: true,
  borderInlineEndColor: true,
  borderBlockStyle: true,
  borderBlockStartStyle: true,
  borderBlockEndStyle: true,
  borderInlineStyle: true,
  borderInlineStartStyle: true,
  borderInlineEndStyle: true,
  borderStartStartRadius: true,
  borderStartEndRadius: true,
  borderEndStartRadius: true,
  borderEndEndRadius: true
};
export const layoutStyleProps = {
  display: true,
  overflow: true,
  flexDirection: true,
  justifyContent: true,
  alignItems: true,
  flexWrap: true,
  alignSelf: true,
  justifySelf: true,
  flex: true,
  flexBasis: true,
  flexGrow: true,
  flexShrink: true,
  order: true,
  gap: true,
  rowGap: true,
  columnGap: true
};
export const positionStyleProps = {
  position: true,
  insetBlockStart: true,
  insetBlockEnd: true,
  insetInlineStart: true,
  insetInlineEnd: true
};
export const sizeStyleProps = {
  blockSize: true,
  minBlockSize: true,
  maxBlockSize: true,
  inlineSize: true,
  minInlineSize: true,
  maxInlineSize: true
};
export const spaceStyleProps = {
  paddingInline: true,
  paddingInlineStart: true,
  paddingInlineEnd: true,
  paddingBlock: true,
  paddingBlockStart: true,
  paddingBlockEnd: true,
  marginInline: true,
  marginInlineStart: true,
  marginInlineEnd: true,
  marginBlock: true,
  marginBlockStart: true,
  marginBlockEnd: true
};
export const textStyleProps = {
  color: true,
  fontStyle: true,
  textAlign: true,
  textTransform: true,
  textDecoration: true,
  wordBreak: true,
  typography: true
};
export const backgroundStyleProps = {
  backgroundColor: true
};
export const boxShadowStyleProps = {
  boxShadow: true
};
export const listStyleProps = {
  listStyleType: true
};
export const mediaStyleProps = {
  objectFit: true
};

/**
 * This object is a collection of all style prop keys that can be passed to
 * components. It is used to efficiently check if a prop passed to a
 * component is a style prop allowed by the design system.
 *
 * A Set data structure is not used because TypeScript doesn't directly
 * enforce the completeness of a Set based on an interface's keys.
 * In practice, this means that if a property is modified in any of the
 * interfaces that make up the `StyleProps` union, TypeScript would not
 * show an error in the Set definition. For that reason, we use an object
 * instead, where the value of each key doesn't matter, only the presence
 * of the key itself.
 */
export const stylePropKeys = Object.assign({}, borderStyleProps, layoutStyleProps, positionStyleProps, sizeStyleProps, spaceStyleProps, textStyleProps, backgroundStyleProps, boxShadowStyleProps, listStyleProps, mediaStyleProps);