import devLogger from 'react-utils/devLogger';
import { stylePropKeys } from './styleProps';
const DOMPropNames = new Set(['id', 'role', 'tabIndex', 'onMouseEnter', 'onMouseLeave']);
const ariaPropNames = new Set(['aria-label', 'aria-labelledby', 'aria-describedby', 'aria-details']);
const linkPropNames = new Set(['href', 'target', 'rel', 'download', 'ping', 'referrerPolicy']);
const imagePropNames = new Set(['alt', 'src', 'srcSet', 'sizes', 'onLoad', 'onError']);
const defaultOpts = {
  isLink: false,
  isImage: false
};
const dataPropRegex = /^(data-.*)$/;

/**
 * Filters props passed to a component into the following categories:
 *
 * - DOM props: data and aria attributes, role, id, etc.
 * - Style props: CSS-conformant style properties allowed by the design system.
 * - Callback props: Event handlers and other functions.
 */
export function filterProps(props, {
  isLink,
  isImage
} = defaultOpts) {
  const domProps = {};
  const styleProps = {};
  const callbackProps = {};
  Object.keys(props).forEach(key => {
    if (DOMPropNames.has(key) || ariaPropNames.has(key) || isLink && linkPropNames.has(key) || isImage && imagePropNames.has(key) || dataPropRegex.test(key)) {
      domProps[key] = props[key];
    } else if (stylePropKeys[key]) {
      styleProps[key] = props[key];
    } else if (typeof props[key] === 'function') {
      callbackProps[key] = props[key];
    } else {
      // In the future, before logging an error we could consider adding
      // another category for `customProps` since that's likely what these are.
      devLogger.warn({
        message: `Unhandled prop: ${key}\n This prop is not recognized as a DOM, style, or callback prop.`,
        key: `foundations-components-filterProps`
      });
    }
  });
  return {
    domProps,
    styleProps,
    callbackProps
  };
}