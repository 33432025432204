import { useFocusRing as useReactAriaFocusRing } from 'react-aria';
import { useTheme } from 'styled-components';

/**
 * Determines whether a focus ring should be shown on the focused element.
 * @example
 * const StyledButton = styled.button(({ $focusRingStyles }) => {...$focusRingStyles});
 *
 * const MyButton = () => {
 *   const {focusProps, focusRingStyles} = useTrellisFocusRing();
 *
 *   // Be sure to merge existing component props with `focusProps` as needed
 *   return <StyledButton {...focusProps} $focusRingStyles={focusRingStyles} />
 * };
 * @param props - Focus ring config.
 * @returns Focus ring state and styles to apply to the focused element. Spread the `focusProps` onto the element that will receive focus. Pass `focusRingStyles` to a Styled Component.
 */
export const useTrellisFocusRing = props => {
  const result = useReactAriaFocusRing(props);
  const theme = useTheme();
  const condition = props !== null && props !== void 0 && props.isTextInput ? result.isFocused : result.isFocusVisible;
  return Object.assign({}, result, {
    focusRingStyles: condition ? {
      outlineWidth: theme.borderWidth[200],
      outlineStyle: 'solid',
      outlineOffset: theme.space[25],
      outlineColor: theme.color['border-primary-subtle-pressed']
    } : {}
  });
};