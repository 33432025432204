import { getTracker } from 'commerce-subscription-lib/public/tracking';
import uniqWith from 'hs-lodash/uniqWith';
import Raven from 'raven-js';
import { isFutureStartDateBillingTerms, isRecurringCheckoutLineItem, isRelativeStartDateBillingTerms } from '../types/utils/typeguards';
import { getLineItemRecurringFrequency } from './recurring';
const subscriptionTracker = getTracker('checkout-ui');

/**
 * returns a date without timezone information (i.e. a date in UTC)
 * @param date Date
 * @returns number milliseconds since epoch til date
 */
function getUTCDate(date) {
  return Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
}
function stringifyBillingTerms({
  termType,
  numPayments,
  initialPayment: {
    type
  },
  delay,
  startDate
}) {
  return `${type}${numPayments}${termType}${delay}${startDate}`;
}
const hasValidTermType = billingTerms => typeof billingTerms.termType === 'string' && billingTerms.termType !== 'ONE_TIME';
function getRelativeDelay(billingTerms) {
  if (!isRelativeStartDateBillingTerms(billingTerms)) {
    return undefined;
  }
  const {
    initialPayment: {
      relativeDelay: {
        delayLength,
        delayUnit
      }
    }
  } = billingTerms;
  return `${delayLength} ${delayUnit}`;
}
function getFutureStartDate(billingTerms) {
  if (!isFutureStartDateBillingTerms(billingTerms)) {
    return undefined;
  }
  const {
    initialPayment: {
      futureStartDate: startDate
    }
  } = billingTerms;
  return startDate;
}
export function trackSubscriptionCreation(paymentSession) {
  try {
    const subscriptions = uniqWith(paymentSession.lineItems.filter(isRecurringCheckoutLineItem).map(li => Object.assign({}, li.recurringBillingTerms, {
      termType: getLineItemRecurringFrequency(li)
    })).filter(hasValidTermType), (a, b) => stringifyBillingTerms(Object.assign({}, a, {
      delay: getRelativeDelay(a),
      startDate: getFutureStartDate(a)
    })) === stringifyBillingTerms(Object.assign({}, b, {
      delay: getRelativeDelay(b),
      startDate: getFutureStartDate(b)
    })));
    const subscriptionEvents = getSubscriptionEvents(subscriptions, paymentSession);
    subscriptionTracker('subscriptionUpsert', subscriptionEvents);
  } catch (error) {
    Raven.captureMessage('trackSubscriptionCreation error', {
      extra: {
        message: error instanceof Error ? error.message : JSON.stringify(error)
      }
    });
  }
}
function getSubscriptionEvents(subscriptions, paymentSession) {
  return subscriptions.map(billingTerms => {
    const {
      numPayments: numberOfPayments,
      termType
    } = billingTerms;
    const numPayments = typeof numberOfPayments !== 'number' ? 0 : numberOfPayments;
    const eventDefaults = {
      createdAt: getUTCDate(new Date()),
      subType: 'create',
      numPayments,
      termType,
      paymentType: paymentSession.processorType.toLowerCase()
    };
    if (isRelativeStartDateBillingTerms(billingTerms)) {
      return Object.assign({}, eventDefaults, {
        type: 'scheduled-subscription',
        delay: getRelativeDelay(billingTerms)
      });
    }
    if (isFutureStartDateBillingTerms(billingTerms)) {
      return Object.assign({}, eventDefaults, {
        type: 'scheduled-subscription',
        startDate: getFutureStartDate(billingTerms)
      });
    }
    return Object.assign({}, eventDefaults, {
      type: 'subscription'
    });
  });
}