import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["data-test-id", "error", "required", "characterCount", "id", "labelAs", "containerAs"];
import { useContext } from 'react';
import { useField as reactAriaUseField, useId as reactAriaUseId } from 'react-aria';
import { getLabelDOMProps } from './Field.helpers';
import { FieldContext } from './FieldContext';
export const useTrellisField = _ref => {
  let {
      'data-test-id': dataTestId,
      error,
      required,
      characterCount,
      id,
      labelAs = 'label',
      containerAs = 'div'
    } = _ref,
    rest = _objectWithoutPropertiesLoose(_ref, _excluded);
  const characterCountId = reactAriaUseId();
  const {
    labelProps: reactAriaLabelProps,
    fieldProps: reactAriaFieldProps,
    descriptionProps: reactAriaDescriptionProps,
    errorMessageProps: reactAriaErrorMessageProps
  } = reactAriaUseField(Object.assign({}, rest, {
    'aria-describedby': characterCount ? characterCountId : undefined
  }, {
    labelElementType: labelAs
  }));
  return {
    fieldControlProps: {
      'aria-describedby': reactAriaFieldProps['aria-describedby'],
      'aria-details': reactAriaFieldProps['aria-details'],
      'aria-invalid': error,
      'aria-label': reactAriaFieldProps['aria-label'],
      'aria-labelledby': reactAriaFieldProps['aria-labelledby'],
      'aria-required': required,
      error,
      id: labelAs === 'label' && rest.label !== undefined || id ? id || reactAriaFieldProps.id : undefined,
      required
    },
    labelProps: Object.assign({}, getLabelDOMProps(reactAriaLabelProps, {
      labelAs,
      id
    }), {
      as: labelAs
    }),
    helpProps: {
      id: reactAriaDescriptionProps.id
    },
    errorOrSuccessProps: {
      id: reactAriaErrorMessageProps.id
    },
    characterCountProps: {
      id: characterCountId
    },
    containerProps: {
      'data-test-id': dataTestId,
      as: containerAs
    }
  };
};
export const useTrellisFieldControl = () => {
  const fieldContext = useContext(FieldContext);
  return Object.assign({}, fieldContext);
};