import PortalIdParser from 'PortalIdParser';
import enviro from 'enviro';
import userInfo from 'hub-http/userInfo';
import { createTracker } from 'usage-tracker';
import events from './events.yaml';
const tracker = createTracker({
  trackerName: 'commerce-subscriptions-tracker',
  events,
  properties: {
    namespace: 'commerce-subscription',
    email: () => userInfo({
      cached: true,
      ignoreRedirect: true
    }).then(({
      user
    }) => user.email).catch(() => 'unknown'),
    hubId: () => {
      var _PortalIdParser$get;
      return (_PortalIdParser$get = PortalIdParser.get()) !== null && _PortalIdParser$get !== void 0 ? _PortalIdParser$get : -1;
    }
  },
  lastKnownEventProperties: ['app', 'paymentType'],
  debug: () => enviro.debug('commerce-subscription-lib'),
  preserveTrackerEvents: true,
  bypassPool: true
});
function track(app, eventName, values) {
  if (values instanceof Array) {
    values.forEach(value => {
      tracker.track(eventName, Object.assign({}, value, {
        app
      }));
    });
  } else {
    tracker.track(eventName, Object.assign({}, values, {
      app
    }));
  }
}
function debug(app, eventName, values) {
  console.group('SubscriptionTracker');
  console.log(`debug: '[Commerce Subscription] ${app} - ${eventName}'`);
  console.table(values);
  console.groupEnd();
}
export function getTracker(app,
// typescript trick to allow autocomplete of known strings
shouldDebug) {
  return (eventName, values) => shouldDebug ? debug(app, eventName, values) : track(app, eventName, values);
}